@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Thin.otf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ThinItalic.otf");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-UltraLight.otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-UltraLightItalic.otf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-RegularItalic.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-SemiBoldItalic.otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ExtraBoldItalic.otf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Heavy.otf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-HeavyItalic.otf");
  font-weight: 900;
  font-style: italic;
}
body {
  font-family: Roboto, Gilroy, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: black;
}

a {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: black;
  text-decoration: underline;
}

button:focus {
  outline: none;
}

tr {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

th {
  padding: 0.5rem;
  font-size: 0.75rem;
  color: #7b809a;
}

td {
  padding: 0.5rem;
}
